import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { deleteScream } from '../../redux/actions/dataActions';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// Icons
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  deleteButton: {
    color: 'white',
    backgroundColor: '#d32f2f',
    '&:hover': {
      backgroundColor: '#b71c1c'
    }
  }
}));

const DeleteScream = ({ screamId, deleteScream }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteScream = () => {
    deleteScream(screamId);
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <Fragment>
      <Tooltip title="Delete Scream">
        <IconButton onClick={handleOpen}>
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Delete Scream?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This can't be undone! The scream will be removed from your profile
            and from the feed of any accounts that follow you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeleteScream}
            variant="contained"
            className={classes.deleteButton}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

DeleteScream.propTypes = {
  deleteScream: PropTypes.func.isRequired,
  screamId: PropTypes.string.isRequired
};

export default connect(null, { deleteScream })(DeleteScream);
