import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { getScreams } from '../redux/actions/dataActions';
// MUI
import Grid from '@material-ui/core/Grid';
// Components
import Scream from '../components/scream/Scream';
import Profile from '../components/profile/Profile';
import ScreamSkeleton from '../components/skeletons/ScreamSkeleton';

const Home = ({ data: { screams, loading }, getScreams }) => {
  useEffect(() => {
    getScreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        {!loading ? (
          screams.map((scream) => <Scream scream={scream} key={scream.id} />)
        ) : (
          //<CircularProgress className={classes.loadingSpinner} />
          <ScreamSkeleton count={3} />
        )}
      </Grid>
      <Grid item sm={4} xs={12}>
        <Profile />
      </Grid>
    </Grid>
  );
};

Home.propTypes = {
  getScreams: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data
});

export default connect(mapStateToProps, { getScreams })(Home);
