import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import PostScream from '../scream/PostScream';
import Notifications from './Notifications';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  navContainer: {
    margin: 'auto'
  }
}));

const NavBar = ({ authenticated }) => {
  const classes = useStyles();

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.navContainer}>
        {authenticated ? (
          <Fragment>
            <PostScream />
            <Tooltip title="Home">
              <IconButton color="inherit" component={Link} to="/">
                <HomeIcon />
              </IconButton>
            </Tooltip>
            <Notifications />
          </Fragment>
        ) : (
          <Fragment>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <IconButton color="inherit" component={Link} to="/">
              <HomeIcon />
            </IconButton>
            <Button color="inherit" component={Link} to="/signup">
              Sign Up
            </Button>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = {
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(NavBar);
