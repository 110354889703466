import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { markNotificationsRead } from '../../redux/actions/userActions';
// MUI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
// Icons
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CommentIcon from '@material-ui/icons/Comment';

const Notifications = ({ notifications, markNotificationsRead }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  dayjs.extend(relativeTime);

  const handleOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = () => {
    let unreadNotifIds = notifications
      .filter((notif) => !notif.read)
      .map((notif) => notif.notificationId);
    if (unreadNotifIds.length > 0) markNotificationsRead(unreadNotifIds);
  };

  let notifIcon = <NotificationsNoneIcon />;
  if (notifications && notifications.length > 0) {
    const numUnread = notifications.filter((notif) => !notif.read).length;
    if (numUnread > 0) {
      notifIcon = (
        <Badge badgeContent={numUnread} color="secondary">
          <NotificationsIcon />
        </Badge>
      );
    }
  }

  let notificationsContent =
    notifications && notifications.length > 0 ? (
      notifications.map((notif) => {
        const verbStr = notif.type === 'like' ? 'liked' : 'commented on';
        const timeStr = dayjs(notif.createdAt).fromNow();
        const iconColor = notif.read ? 'primary' : 'secondary';
        const icon =
          notif.type === 'like' ? (
            <FavoriteIcon color={iconColor} style={{ marginRight: 8 }} />
          ) : (
            <CommentIcon color={iconColor} style={{ marginRight: 8 }} />
          );

        return (
          <MenuItem
            key={notif.notificationId}
            onClick={handleClose}
            component={Link}
            to={`/users/${notif.recipient}/scream/${notif.screamId}`}
          >
            {icon}
            <Typography color="textPrimary" variant="body1">
              {notif.sender} {verbStr} your scream{' '}
              <span color="textSecondary">{timeStr}</span>
            </Typography>
          </MenuItem>
        );
      })
    ) : (
      <MenuItem onClick={handleClose}>
        You don't have any notifications yet!
      </MenuItem>
    );

  return (
    <Fragment>
      <Tooltip title="Notifications">
        <IconButton
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={handleOpen}
          color="inherit"
        >
          {notifIcon}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onEntered={handleMenuOpen}
      >
        {notificationsContent}
      </Menu>
    </Fragment>
  );
};

Notifications.propTypes = {
  markNotificationsRead: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  notifications: state.user.notifications
});

export default connect(mapStateToProps, { markNotificationsRead })(
  Notifications
);
