import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
// MUI
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
// Icons
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';

const CommentButton = ({ commentCount, authenticated, onClick }) => {
  const commentButton = authenticated ? (
    <Tooltip title="Comment">
      <Button startIcon={<CommentOutlinedIcon />} onClick={onClick}>
        {commentCount}
      </Button>
    </Tooltip>
  ) : (
    <Tooltip title="Comment">
      <Button
        startIcon={<CommentOutlinedIcon />}
        component={Link}
        to={'/login'}
      >
        {commentCount}
      </Button>
    </Tooltip>
  );
  return commentButton;
};

CommentButton.propTypes = {
  commentCount: PropTypes.number.isRequired,
  authenticated: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(CommentButton);
