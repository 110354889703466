import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { postScream, clearErrors } from '../../redux/actions/dataActions';
// MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// Icons
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis
}));

const PostScream = ({ ui: { loading, errors }, postScream, clearErrors }) => {
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBody('');
    clearErrors();
  };

  const handleChange = (event) => {
    clearErrors();
    setBody(event.target.value);
  };

  const handleSubmit = async () => {
    const success = await postScream({ body: body });
    // Close dialog if no errors
    if (success) {
      handleClose();
    }
  };

  const classes = useStyles();
  return (
    <Fragment>
      <Tooltip title="Post a Scream!">
        <IconButton color="inherit" onClick={handleOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Post a Scream!</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              name="body"
              type="text"
              label="SCREAM!"
              variant="outlined"
              multiline
              rows="3"
              placeholder="PINEAPPLE DOES NOT BELONG ON PIZZA"
              error={errors.body ? true : false}
              helperText={errors.body}
              className={classes.textField}
              onChange={handleChange}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                color="primary"
                size={20}
                className={classes.progressSpinner}
              />
            )}
            Post
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

PostScream.propTypes = {
  postScream: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  ui: state.ui
});

const mapActionsToProps = { postScream, clearErrors };

export default connect(mapStateToProps, mapActionsToProps)(PostScream);
