import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import axios from 'axios';
// Redux
import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/userActions';
// MUI
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// Img
import { ReactComponent as LoudNoisesLogo } from '../img/loud.svg';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis
}));

const Signup = ({ history, signupUser, ui: { loading, errors } }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [handle, setHandle] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Authenticate
    const newUserData = {
      email,
      password,
      confirmPassword,
      handle
    };
    signupUser(newUserData, history);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    } else if (name === 'handle') {
      setHandle(value);
    }
  };

  const classes = useStyles();

  return (
    <Container maxWidth="xs" className={classes.page} align="center">
      <LoudNoisesLogo title="Loud Noises Logo" className={classes.logo} />
      <Typography variant="h3" component="h1">
        Sign Up
      </Typography>
      <form noValidate onSubmit={handleSubmit} className={classes.form}>
        <TextField
          id="email"
          name="email"
          type="email"
          label="Email"
          className={classes.textField}
          value={email}
          error={errors.email ? true : false}
          helperText={errors.email}
          variant="outlined"
          onChange={handleChange}
          fullWidth
        />
        <TextField
          id="password"
          name="password"
          type="password"
          label="Password"
          className={classes.textField}
          error={errors.password ? true : false}
          helperText={errors.password}
          value={password}
          variant="outlined"
          onChange={handleChange}
          fullWidth
        />
        <TextField
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          className={classes.textField}
          error={errors.confirmPassword ? true : false}
          helperText={errors.confirmPassword}
          value={confirmPassword}
          variant="outlined"
          onChange={handleChange}
          fullWidth
        />
        <TextField
          id="handle"
          name="handle"
          type="text"
          label="Handle"
          className={classes.textField}
          error={errors.handle ? true : false}
          helperText={errors.handle}
          value={handle}
          variant="outlined"
          onChange={handleChange}
          fullWidth
        />
        {errors.general && (
          <Typography
            variant="body1"
            color="error"
            className={classes.generalError}
          >
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          className={classes.submitButton}
        >
          {loading && (
            <CircularProgress
              color="primary"
              size={20}
              className={classes.progressSpinner}
            />
          )}
          Sign Up
        </Button>
      </form>
      <Typography
        variant="body2"
        color="primary"
        className={classes.link}
        component={Link}
        to="/login"
      >
        Already have an account? Log In
      </Typography>
    </Container>
  );
};

Signup.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
});

export default connect(mapStateToProps, { signupUser })(Signup);
