import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
// MUI
import Paper from '@material-ui/core/Paper';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// Icons
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LinkIcon from '@material-ui/icons/Link';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  ...theme.spreadThis.profile
}));

const StaticProfile = ({
  profile: { handle, createdAt, imageUrl, bio, website, location }
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.profileContainer}>
      <div className={classes.imageSection}>
        <div className={classes.profileImgWrapper}>
          <img src={imageUrl} alt={handle} className={classes.profileImage} />
        </div>
      </div>
      <div className={classes.profileRow}>
        <MuiLink
          component={Link}
          to={`/users/${handle}`}
          color="primary"
          variant="h5"
        >
          {handle}
        </MuiLink>
      </div>
      {bio && (
        <div className={classes.profileRow}>
          <Typography variant="body2">{bio}</Typography>
        </div>
      )}
      {location && (
        <div className={classes.profileRow}>
          <LocationOnIcon color="primary" className={classes.icon} />
          <Typography display="inline">{location}</Typography>
        </div>
      )}
      {website && (
        <div className={classes.profileRow}>
          <LinkIcon color="primary" className={classes.icon} />
          <MuiLink href={website} target="_blank" rel="noopener">
            {website}
          </MuiLink>
        </div>
      )}
      <div className={classes.profileRow}>
        <CalendarTodayIcon color="primary" className={classes.icon} />
        <Typography display="inline">
          Joined {dayjs(createdAt).format('MMM YYYY')}
        </Typography>
      </div>
    </Paper>
  );
};

StaticProfile.propTypes = {
  profile: PropTypes.object.isRequired
};

export default StaticProfile;
