import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
// Components
import DeleteScream from './DeleteScream';
import ScreamDialog from './ScreamDialog';
import LikeButton from './LikeButton';
import CommentButton from './CommentButton';
// Redux
import { connect } from 'react-redux';
import {
  getScream,
  closeScream,
  clearErrors
} from '../../redux/actions/dataActions';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  ...theme.spreadThis.screamCard
}));

const Scream = ({
  scream: {
    body,
    createdAt,
    userHandle,
    userImage,
    likeCount,
    commentCount,
    id
  },
  user: {
    authenticated,
    likes,
    credentials: { handle }
  },
  getScream,
  closeScream,
  clearErrors,
  openDialog
}) => {
  const [open, setOpen] = useState(false);
  const [prevPath, setPrevPath] = useState('');

  const handleOpen = () => {
    // Change URL to current scream
    let oldPath = window.location.pathname;
    const newPath = `/users/${userHandle}/scream/${id}`;

    if (oldPath === newPath) {
      oldPath = `/users/${userHandle}`;
    }
    setPrevPath(oldPath);

    const newTitle = `${userHandle} on Loud Noises: "${body}"`;
    window.history.pushState(null, newTitle, newPath);

    setOpen(true);
    getScream(id);
  };

  const handleClose = () => {
    window.history.pushState(null, null, prevPath);
    setOpen(false);
    closeScream();
    clearErrors();
  };

  // Open dialog on mount if openDialog prop is true
  useEffect(() => {
    if (openDialog) {
      handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteButton =
    authenticated && userHandle === handle ? (
      <DeleteScream screamId={id} />
    ) : null;

  dayjs.extend(relativeTime);

  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            src={userImage}
            alt={`Profile image of ${userHandle}`}
            className={classes.avatar}
            component={Link}
            to={`/users/${userHandle}`}
          />
        }
        title={userHandle}
        titleTypographyProps={{
          component: Link,
          to: `/users/${userHandle}`,
          className: classes.title
        }}
        subheader={dayjs(createdAt).from()}
        action={deleteButton}
      />
      <CardActionArea onClick={handleOpen}>
        <CardContent>
          <Typography>{body}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <LikeButton id={id} likeCount={likeCount} />
        <CommentButton commentCount={commentCount} onClick={handleOpen} />
      </CardActions>
      <ScreamDialog
        open={open}
        screamId={id}
        userHandle={userHandle}
        onClose={handleClose}
      />
    </Card>
  );
};

Scream.propTypes = {
  user: PropTypes.object.isRequired,
  scream: PropTypes.object.isRequired,
  getScream: PropTypes.func.isRequired,
  closeScream: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  openDialog: PropTypes.bool
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionsToProps = {
  getScream,
  closeScream,
  clearErrors
};

export default connect(mapStateToProps, mapActionsToProps)(Scream);
