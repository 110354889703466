import React from 'react';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  ...theme.spreadThis.profile,
  typography: {
    ...theme.typography
  },
  profileImg: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  handle: {
    fontSize: '2rem',
    width: '50%'
  },
  bio: {
    height: '1.25rem',
    width: '70%'
  },
  location: {
    height: '1.5rem',
    width: '50%'
  },
  website: {
    height: '1.5rem',
    width: '70%'
  },
  dateJoined: {
    height: '1.5rem',
    width: '60%'
  }
}));

const ProfileSkeleton = () => {
  const classes = useStyles();

  const animation = 'wave'; // 'wave', false

  return (
    <Paper className={classes.profileContainer}>
      <div className={classes.imageSection}>
        <div className={classes.profileImgWrapper}>
          <Skeleton
            animation={animation}
            variant="circle"
            className={classes.profileImg}
          />
        </div>
      </div>
      <div className={classes.handle}>
        <Skeleton animation={animation} />
      </div>
      <div className={classes.bio}>
        <Skeleton animation={animation} />
      </div>
      <div className={classes.location}>
        <Skeleton animation={animation} />
      </div>
      <div className={classes.website}>
        <Skeleton animation={animation} />
      </div>
      <div className={classes.dateJoined}>
        <Skeleton animation={animation} />
      </div>
    </Paper>
  );
};

export default ProfileSkeleton;
