import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// Components
import EditDetailsDialog from './EditDetailsDialog';
import TooltipButton from '../scream/TooltipButton';
import ProfileSkeleton from '../skeletons/ProfileSkeleton';
// Redux
import { connect } from 'react-redux';
import { logoutUser, uploadImage } from '../../redux/actions/userActions';
// MUI
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LinkIcon from '@material-ui/icons/Link';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EditIcon from '@material-ui/icons/Edit';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  ...theme.spreadThis.profile
}));

const Profile = ({
  uploadImage,
  logoutUser,
  user: {
    authenticated,
    loading,
    credentials: { imageUrl, handle, createdAt, bio, website, location }
  }
}) => {
  const [open, setOpen] = useState(false);

  const handleImgChange = (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    uploadImage(formData);
  };

  const handleEditImg = () => {
    const fileInput = document.getElementById('userImg');
    fileInput.click();
  };

  const handleLogout = () => {
    logoutUser();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const authenticatedProfile = (
    <Paper className={classes.profileContainer}>
      <div className={classes.imageSection}>
        <div className={classes.profileImgWrapper}>
          <img src={imageUrl} alt={handle} className={classes.profileImage} />
          <input type="file" id="userImg" onChange={handleImgChange} hidden />
          <div className={classes.editImgWrapper}>
            <TooltipButton
              title="Edit profile image"
              onClick={handleEditImg}
              btnClassName={classes.editImgButton}
            >
              <AddAPhotoIcon color="primary" />
            </TooltipButton>
          </div>
        </div>
      </div>
      <div className={classes.profileRow}>
        <MuiLink
          component={Link}
          to={`/users/${handle}`}
          color="primary"
          variant="h5"
        >
          {handle}
        </MuiLink>
      </div>
      {bio && (
        <div className={classes.profileRow}>
          <Typography variant="body2">{bio}</Typography>
        </div>
      )}
      {location && (
        <div className={classes.profileRow}>
          <LocationOnIcon color="primary" className={classes.icon} />
          <Typography display="inline">{location}</Typography>
        </div>
      )}
      {website && (
        <div className={classes.profileRow}>
          <LinkIcon color="primary" className={classes.icon} />
          <MuiLink href={website} target="_blank" rel="noopener">
            {website}
          </MuiLink>
        </div>
      )}
      <div className={classes.profileRow}>
        <CalendarTodayIcon color="primary" className={classes.icon} />
        <Typography display="inline">
          Joined {dayjs(createdAt).format('MMM YYYY')}
        </Typography>
      </div>
      <Button
        onClick={handleLogout}
        variant="contained"
        color="secondary"
        className={classes.authButton}
      >
        Log Out
        <ExitToAppIcon className={classes.logoutIcon} />
      </Button>
      <Tooltip title="Edit details" placement="bottom">
        <div className={classes.editProfileWrapper}>
          <IconButton onClick={handleOpen} className={classes.editButton}>
            <EditIcon color="primary" />
          </IconButton>
        </div>
      </Tooltip>
      <EditDetailsDialog open={open} onClose={handleClose} />
    </Paper>
  );

  const guestProfile = (
    <Paper className={classes.profileContainer}>
      <Typography align="center">
        No profile to show, please login or sign up.
      </Typography>
      <div className={classes.authContainer}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
          className={classes.authButton}
        >
          Login
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/signup"
          className={classes.authButton}
        >
          Signup
        </Button>
      </div>
    </Paper>
  );

  const loadingProfile = <ProfileSkeleton />;

  const profileMarkup = loading
    ? loadingProfile
    : authenticated
    ? authenticatedProfile
    : guestProfile;

  return profileMarkup;
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionsToProps = { logoutUser, uploadImage };

export default connect(mapStateToProps, mapActionsToProps)(Profile);
