import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export default ({
  children,
  onClick,
  title,
  btnClassName,
  tooltipClassName
}) => (
  <Tooltip title={title} className={tooltipClassName}>
    <IconButton color="inherit" onClick={onClick} className={btnClassName}>
      {children}
    </IconButton>
  </Tooltip>
);
