import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { likeScream, unlikeScream } from '../../redux/actions/dataActions';
// MUI
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
// Icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const LikeButton = ({
  id,
  likeCount,
  user: { authenticated, likes },
  likeScream,
  unlikeScream
}) => {
  const hasLikedScream = () =>
    likes && likes.find((like) => like.screamId === id);

  const handleLikeScream = () => {
    likeScream(id);
  };

  const handleUnlikeScream = () => {
    unlikeScream(id);
  };
  const likeButton = authenticated ? (
    hasLikedScream() ? (
      <Tooltip title="Unlike">
        <Button startIcon={<FavoriteIcon />} onClick={handleUnlikeScream}>
          {likeCount}
        </Button>
      </Tooltip>
    ) : (
      <Tooltip title="Like">
        <Button startIcon={<FavoriteBorderIcon />} onClick={handleLikeScream}>
          {likeCount}
        </Button>
      </Tooltip>
    )
  ) : (
    <Tooltip title="Like">
      <Button startIcon={<FavoriteBorderIcon />} component={Link} to={'/login'}>
        {likeCount}
      </Button>
    </Tooltip>
  );
  return likeButton;
};

LikeButton.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  likeCount: PropTypes.number.isRequired,
  likeScream: PropTypes.func.isRequired,
  unlikeScream: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionsToProps = {
  likeScream,
  unlikeScream
};

export default connect(mapStateToProps, mapActionsToProps)(LikeButton);
