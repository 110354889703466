import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// Redux
import { connect } from 'react-redux';
// Components
import LikeButton from './LikeButton';
import CommentButton from './CommentButton';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
// Icons
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  dialogContent: {
    padding: theme.spacing(4)
  },
  screamContainer: {
    position: 'relative'
  },
  userImageSection: {
    width: 200,
    maxWidth: '100%',
    margin: '0 auto'
  },
  userImageWrapper: {
    padding: '50%',
    position: 'relative'
  },
  userImage: {
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%'
  },
  screamHandle: {
    textDecoration: 'none',
    color: 'black'
  },
  loadingSpinner: {
    display: 'block',
    margin: 'auto'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  screamButtons: {
    position: 'absolute',
    bottom: 0,
    marginTop: theme.spacing(2)
  },
  screamBodyText: {
    marginBottom: theme.spacing(5)
  }
}));

const ScreamDialog = ({
  open,
  onClose,
  activeScream: {
    id,
    body,
    createdAt,
    likeCount,
    commentCount,
    userImage,
    userHandle,
    comments
  },
  ui: { loading }
}) => {
  const classes = useStyles();

  const dialogMarkup = loading ? (
    <CircularProgress className={classes.loadingSpinner} />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={3} sm={5}>
        <div className={classes.userImageSection}>
          <div className={classes.userImageWrapper}>
            <img src={userImage} alt={''} className={classes.userImage} />
          </div>
        </div>
      </Grid>
      <Grid item xs={9} sm={7} className={classes.screamContainer}>
        <Typography
          variant="h5"
          className={classes.screamHandle}
          component={Link}
          to={`/users/${userHandle}`}
        >
          {userHandle}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
        </Typography>
        <Typography className={classes.screamBodyText}>{body}</Typography>
        <div className={classes.screamButtons}>
          <LikeButton id={id} likeCount={likeCount} />
          <CommentButton commentCount={commentCount} />
        </div>
      </Grid>
      <Divider variant="middle" className={classes.divider} />
      <CommentForm screamId={id} />
      <CommentList comments={comments} />
    </Grid>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        {dialogMarkup}
      </DialogContent>
    </Dialog>
  );
};

ScreamDialog.propTypes = {
  activeScream: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  activeScream: state.data.activeScream,
  ui: state.ui
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(ScreamDialog);
