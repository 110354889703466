import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
//import axios from 'axios';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';
// MUI
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// Img
import { ReactComponent as LoudNoisesLogo } from '../img/loud.svg';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis
}));

const Login = ({ history, loginUser, ui: { loading, errors } }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  //const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = { email, password };
    loginUser(userData, history);
  };

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    } else if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
  };

  const classes = useStyles();

  return (
    <Container maxWidth="xs" className={classes.page} align="center">
      <LoudNoisesLogo title="Loud Noises Logo" className={classes.logo} />
      <Typography variant="h3" component="h1">
        Login
      </Typography>
      <form noValidate onSubmit={handleSubmit} className={classes.form}>
        <TextField
          id="email"
          name="email"
          type="email"
          label="Email"
          className={classes.textField}
          value={email}
          error={errors.email ? true : false}
          helperText={errors.email}
          variant="outlined"
          onChange={handleChange}
          fullWidth
        />
        <TextField
          id="password"
          name="password"
          type="password"
          label="Password"
          className={classes.textField}
          error={errors.password ? true : false}
          helperText={errors.password}
          value={password}
          variant="outlined"
          onChange={handleChange}
          fullWidth
        />
        {errors.general && (
          <Typography
            variant="body1"
            color="error"
            className={classes.generalError}
          >
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          className={classes.submitButton}
        >
          {loading && (
            <CircularProgress
              color="primary"
              size={20}
              className={classes.progressSpinner}
            />
          )}
          Submit
        </Button>
      </form>
      <Typography
        variant="body2"
        color="primary"
        className={classes.link}
        component={Link}
        to="/signup"
      >
        Don't have an account? Sign Up
      </Typography>
    </Container>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.ui
});

const mapActionsToProps = { loginUser };

export default connect(mapStateToProps, mapActionsToProps)(Login);
