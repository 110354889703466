import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  userImageSection: {
    width: 56,
    maxWidth: '100%',
    margin: '0 auto'
  },
  userImageWrapper: {
    padding: '50%',
    position: 'relative'
  },
  userImage: {
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%'
  }
}));

const CommentList = ({ comments }) => {
  const classes = useStyles();
  return (
    <Grid container>
      {comments
        ? comments.map((comment, index) => {
            const {
              body,
              createdAt,
              userImage,
              userHandle,
              commentId
            } = comment;
            return (
              <Fragment key={commentId}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <div className={classes.userImageSection}>
                        <div className={classes.userImageWrapper}>
                          <img
                            src={userImage}
                            className={classes.userImage}
                            alt=""
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={9} sm={10}>
                      <div className={classes.commentData}>
                        <Typography
                          variant="h6"
                          component={Link}
                          to={`/users/${userHandle}`}
                          color="primary"
                        >
                          {userHandle}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          gutterBottom
                        >
                          {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                        </Typography>
                        <Typography>{body}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {index !== comments.length - 1 && (
                  <Divider variant="middle" className={classes.divider} />
                )}
              </Fragment>
            );
          })
        : null}
    </Grid>
  );
};

CommentList.propTypes = {
  comments: PropTypes.array
};

export default CommentList;
