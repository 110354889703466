import React, { useState } from 'react';
import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
// Redux
import { connect } from 'react-redux';
import { submitComment, clearErrors } from '../../redux/actions/dataActions';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  commentForm: {
    textAlign: 'center'
  }
}));

const CommentForm = ({
  ui: { errors },
  authenticated,
  screamId,
  submitComment,
  clearErrors
}) => {
  const [body, setBody] = useState('');

  const handleChange = (event) => {
    clearErrors();
    setBody(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const success = await submitComment(screamId, { body: body });
    if (success) {
      setBody('');
    }
  };

  const classes = useStyles();

  const commentForm = authenticated ? (
    <Grid item xs={12} className={classes.commentForm}>
      <form onSubmit={handleSubmit}>
        <TextField
          name="body"
          type="text"
          label="Comment on Scream"
          error={errors.comment ? true : false}
          helperText={errors.comment}
          value={body}
          onChange={handleChange}
          fullWidth
          className={classes.textField}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
      <Divider variant="middle" className={classes.divider} />
    </Grid>
  ) : null;

  return commentForm;
};

CommentForm.propTypes = {
  submitComment: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  screamId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  ui: state.ui,
  authenticated: state.user.authenticated
});

const mapActionsToProps = {
  submitComment,
  clearErrors
};

export default connect(mapStateToProps, mapActionsToProps)(CommentForm);
