import {
  SET_SCREAMS,
  LOADING_DATA,
  LIKE_SCREAM,
  UNLIKE_SCREAM,
  DELETE_SCREAM,
  POST_SCREAM,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  SET_ACTIVE_SCREAM,
  SUBMIT_COMMENT
} from '../types';
import axios from 'axios';

// Get all screams
export const getScreams = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.get('/screams');
    dispatch({ type: SET_SCREAMS, payload: res.data });
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_SCREAMS, payload: [] });
  }
};

// Get details of single scream, including comments
export const getScream = (screamId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get(`/scream/${screamId}`);
    dispatch({ type: SET_ACTIVE_SCREAM, payload: res.data });
    dispatch({ type: STOP_LOADING_UI });
  } catch (err) {
    console.error(err);
  }
};

// Clear active scream state when dialog is closed
export const closeScream = () => (dispatch) => {
  dispatch({ type: SET_ACTIVE_SCREAM, payload: {} });
};

// Post a scream
export const postScream = (newScream) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.post('/scream', newScream);
    dispatch({ type: POST_SCREAM, payload: res.data });
    dispatch({ type: CLEAR_ERRORS });
    return true;
  } catch (err) {
    dispatch({ type: SET_ERRORS, payload: err.response.data });
    return false;
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// Like a scream
export const likeScream = (screamId) => async (dispatch) => {
  try {
    const res = await axios.post(`/scream/${screamId}/like`);
    dispatch({ type: LIKE_SCREAM, payload: res.data });
  } catch (err) {
    console.error(err);
  }
};

// Unlike a scream
export const unlikeScream = (screamId) => async (dispatch) => {
  try {
    const res = await axios.post(`/scream/${screamId}/unlike`);
    dispatch({ type: UNLIKE_SCREAM, payload: res.data });
  } catch (err) {
    console.error(err);
  }
};

// Submit a comment
export const submitComment = (screamId, commentData) => async (dispatch) => {
  try {
    const res = await axios.post(`/scream/${screamId}/comment`, commentData);
    dispatch({ type: SUBMIT_COMMENT, payload: res.data });
    dispatch({ type: CLEAR_ERRORS });
    return true;
  } catch (err) {
    dispatch({ type: SET_ERRORS, payload: err.response.data });
    return false;
  }
};

// Delete a scream
export const deleteScream = (screamId) => async (dispatch) => {
  try {
    await axios.delete(`/scream/${screamId}`);
    dispatch({ type: DELETE_SCREAM, payload: screamId });
  } catch (err) {
    console.error(err);
  }
};

// Get public profile data for user's page
export const getProfile = (userHandle) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.get(`/user/${userHandle}`);
    dispatch({ type: SET_SCREAMS, payload: res.data.screams });
    return res.data.user;
  } catch (err) {
    dispatch({ type: SET_SCREAMS, payload: [] });
    console.error(err);
    return false;
  }
};
