import {
  SET_USER,
  SET_ERRORS,
  SET_UNAUTHENTICATED,
  CLEAR_ERRORS,
  LOADING_UI,
  LOADING_USER,
  FORGET_IMAGE_URL,
  MARK_NOTIFICATIONS_READ
} from '../types';
import axios from 'axios';

export const loginUser = (userData, history) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.post('/login', userData);
    setAuthHeader(res.data.idToken);
    dispatch(getUserData());
    dispatch({ type: CLEAR_ERRORS });
    history.push('/');
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

export const signupUser = (newUserData, history) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.post('/signup', newUserData);
    setAuthHeader(res.data.idToken);
    dispatch(getUserData());
    dispatch({ type: CLEAR_ERRORS });
    history.push('/');
  } catch (err) {
    console.log(err);
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('idToken');
  delete axios.defaults.headers.common['Authorization'];
  dispatch({ type: SET_UNAUTHENTICATED });
};

export const getUserData = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const res = await axios.get('/user');
    dispatch({
      type: SET_USER,
      payload: res.data
    });
  } catch (err) {
    console.error(err);
  }
};

export const uploadImage = (formData) => async (dispatch) => {
  try {
    // Forget profile image
    dispatch({ type: FORGET_IMAGE_URL });
    dispatch({ type: LOADING_USER });
    // Upload new image to server
    await axios.post('/user/image', formData);
    // Fetch updated user data for frontend
    dispatch(getUserData());
  } catch (err) {
    console.error(err);
  }
};

export const editUserDetails = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    // Send new details to server
    await axios.post('/user', userDetails);
    // Fetch updated user details for frontend
    dispatch(getUserData());
  } catch (err) {
    console.error(err);
  }
};

export const markNotificationsRead = (notificationIds) => async (dispatch) => {
  try {
    await axios.post('/notifications', notificationIds);
    dispatch({ type: MARK_NOTIFICATIONS_READ });
  } catch (err) {
    console.error(err);
  }
};

const setAuthHeader = (token) => {
  const fbIdToken = `Bearer ${token}`;
  localStorage.setItem('idToken', fbIdToken);
  axios.defaults.headers.common['Authorization'] = fbIdToken;
};
