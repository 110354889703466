import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../../redux/actions/userActions';
// MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
  textField: {
    marginBottom: theme.spacing(3)
  }
}));

const EditDetailsDialog = ({
  open,
  onClose,
  bio,
  location,
  website,
  editUserDetails
}) => {
  const [details, setDetails] = useState({ bio, location, website });

  const handleChange = (event) => {
    // TODO: Let user update fields and only sync to state when saved
    setDetails({
      ...details,
      [event.target.name]: event.target.value
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    editUserDetails(details);
    // TODO: Update details on server
    onClose();
  };

  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Edit your details</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            name="bio"
            type="text"
            label="Bio"
            variant="outlined"
            multiline
            rows="2"
            fullWidth
            placeholder="INTRODUCE YOURSELF!! !!!!!"
            className={classes.textField}
            value={details.bio}
            onChange={handleChange}
          />
          <TextField
            name="location"
            type="text"
            label="Location"
            variant="outlined"
            fullWidth
            placeholder="Where can users find you when you say 'Fight me irl'?"
            className={classes.textField}
            value={details.location}
            onChange={handleChange}
          />
          <TextField
            name="website"
            type="text"
            label="Website"
            variant="outlined"
            fullWidth
            placeholder="Where can users find you when you say 'Fight me, but not irl'?"
            className={classes.textField}
            value={details.website}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="outlined" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditDetailsDialog.propTypes = {
  editUserDetails: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  bio: state.user.credentials.bio,
  location: state.user.credentials.location,
  website: state.user.credentials.website
});

export default connect(mapStateToProps, { editUserDetails })(EditDetailsDialog);
