const spacing = (factor) => factor * 8;

export default {
  palette: {
    primary: {
      main: '#6200ee',
      dark: '#3700B3',
      contrastText: '#fff'
    },
    secondary: {
      main: '#03dac5',
      contrastText: '#000'
    },
    error: {
      main: '#B00020'
    },
    background: {
      paper: '#fff',
      default: '#fff'
    }
  },
  spreadThis: {
    typography: {
      useNextVariants: true
    },
    screamCard: {
      card: {
        marginBottom: spacing(2)
      },
      avatar: {
        width: spacing(7),
        height: spacing(7)
      },
      title: {
        fontSize: '1.2rem',
        color: 'black',
        textDecoration: 'none',
        display: 'inline-block'
      },
      header: {
        zIndex: 900
      }
    },
    profile: {
      profileContainer: {
        padding: spacing(2),
        textAlign: 'center',
        alignItems: 'center',
        position: 'relative'
      },
      imageSection: {
        width: 200,
        maxWidth: '100%',
        display: 'inline-block'
      },
      profileImgWrapper: {
        position: 'relative',
        padding: '50%',
        marginTop: spacing(1),
        marginBottom: spacing(1)
      },
      profileImage: {
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '50%',
        border: '4px solid #6200ee'
      },
      profileRow: {
        marginTop: spacing(1),
        marginBottom: spacing(1)
      },
      authContainer: {
        textAlign: 'center'
      },
      authButton: {
        marginTop: spacing(2),
        marginBottom: spacing(2),
        marginLeft: spacing(1),
        marginRight: spacing(1)
      },
      icon: {
        verticalAlign: 'middle',
        marginRight: spacing(1)
      },
      logoutIcon: {
        verticalAlign: 'middle',
        marginLeft: spacing(1)
      },
      editImgWrapper: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        backgroundColor: '#fff',
        border: '4px solid #03dac5',
        borderRadius: '50%'
      },
      editProfileWrapper: {
        position: 'absolute',
        right: spacing(-3),
        bottom: spacing(-3),
        backgroundColor: '#fff',
        border: '4px solid #03dac5',
        borderRadius: '50%'
      }
    },
    skeleton: {
      profileRow: {
        marginTop: spacing(1),
        marginBottom: spacing(1),
        margin: 'auto'
      }
    },
    layout: {
      container: {
        marginTop: 64
      }
    },
    page: {
      padding: spacing(2)
    },
    logo: {
      width: spacing(7),
      margin: spacing(2)
    },
    form: {
      margin: spacing(3)
    },
    textField: {
      marginBottom: spacing(2)
    },
    submitButton: {
      marginTop: spacing(2)
    },
    link: {
      textDecoration: 'none'
    },
    progressSpinner: {
      marginRight: spacing(2)
    },
    divider: {
      flexGrow: 1,
      marginTop: spacing(2),
      marginBottom: spacing(2)
    }
  }
};
