import React from 'react';
import PropTypes from 'prop-types';
// MUI
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis.screamCard,
  typography: {
    ...theme.typography
  },
  button: {
    width: 48,
    height: 24.5,
    padding: 6
  }
}));

const ScreamSkeleton = ({ count = 1 }) => {
  const classes = useStyles();

  const animation = 'wave'; // 'wave', false

  const screamCardSkeleton = (key) => (
    <Card className={classes.card} key={key}>
      <CardHeader
        avatar={
          <Skeleton
            animation={animation}
            variant="circle"
            className={classes.avatar}
          />
        }
        title={
          <Skeleton
            animation={animation}
            className={classes.title}
            width="20%"
          />
        }
        subheader={
          <Skeleton
            animation={animation}
            className={classes.typography.body2}
            width="30%"
          />
        }
      />
      <CardContent>
        <Skeleton
          animation={animation}
          className={classes.typography.body1}
          width={`${Math.round(Math.random() * 50) + 20}%`}
        />
        <Skeleton
          animation={animation}
          className={classes.typography.body1}
          width={`${Math.round(Math.random() * 50) + 20}%`}
        />
      </CardContent>
      <CardActions>
        <Skeleton
          animation={animation}
          variant="rect"
          className={classes.button}
        />
        <Skeleton
          animation={animation}
          variant="rect"
          className={classes.button}
        />
      </CardActions>
    </Card>
  );

  const screams = Array.from(new Array(count)).map((item, index) =>
    screamCardSkeleton(index)
  );

  return screams;
};

ScreamSkeleton.propTypes = {
  count: PropTypes.number
};

export default ScreamSkeleton;
