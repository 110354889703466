import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { getProfile } from '../redux/actions/dataActions';
// Components
import Scream from '../components/scream/Scream';
import StaticProfile from '../components/profile/StaticProfile';
import ScreamSkeleton from '../components/skeletons/ScreamSkeleton';
import ProfileSkeleton from '../components/skeletons/ProfileSkeleton';
// MUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const User = ({ data: { loading, screams }, getProfile }) => {
  const { handle, screamId } = useParams();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      const profileData = await getProfile(handle);
      setProfile(profileData);
    };
    fetchProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handle]);

  const screamsList = loading ? (
    //<CircularProgress className={classes.loadingSpinner} />
    <ScreamSkeleton count={3} />
  ) : screams.length === 0 ? (
    <Typography variant="body1">
      This user has not posted any screams!
    </Typography>
  ) : screamId ? (
    screams.map((scream) => (
      <Scream
        scream={scream}
        key={scream.id}
        openDialog={scream.id === screamId}
      />
    ))
  ) : (
    screams.map((scream) => <Scream scream={scream} key={scream.id} />)
  );

  const profileSection =
    profile === null ? (
      <ProfileSkeleton />
    ) : (
      <StaticProfile profile={profile} />
    );

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        {screamsList}
      </Grid>
      <Grid item sm={4} xs={12}>
        {profileSection}
      </Grid>
    </Grid>
  );
};

User.propTypes = {
  getProfile: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data
});

export default connect(mapStateToProps, { getProfile })(User);
